import CaptchaComponent from '@wix/thunderbolt-elements/src/components/Captcha/viewer/Captcha';
import CaptchaController from '@wix/thunderbolt-elements/src/components/Captcha/viewer/Captcha.controller';


const Captcha = {
  component: CaptchaComponent,
  controller: CaptchaController
};


export const components = {
  ['Captcha']: Captcha
};

